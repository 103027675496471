<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('dae_grant_allocation_distribution.purchase_register') }}
              <slot v-if="$route.query.id">
                {{ $t('globalTrans.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="purchase-register" :class="'btn btn-success text-light'">{{ $t('dae_grant_allocation_distribution.purchase_register') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-overlay :show="isLoading">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <div class="row">
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('barc_config.fiscal_year') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="formData.fiscal_year_id"
                                :options="fiscalList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Season Name" vid="season_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="season_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('budget.season') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="formData.season_id"
                                :options="seasonList"
                                id="season_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Subsidy Type" vid="subsidy_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="subsidy_type_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('bsri_config.subsidy_type') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="formData.subsidy_type_id"
                                :options="subsidyTypeList"
                                id="subsidy_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Subsidy Name" vid="subsidy_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="subsidy_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('bsri_config.subsidy_name') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="formData.subsidy_id"
                                :options="subsidyList"
                                id="subsidy_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Subsidy Name" vid="project_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="project_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('bsri_config.project_name') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="formData.project_id"
                                :options="projectList"
                                id="project_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Divison" vid="division_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="division_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                  {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.division_id"
                                  :options="divisionList"
                                  id="division_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.district_id"
                                  :options="districtList"
                                  id="district_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Upazilla" vid="upazilla_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="upazilla_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-select
                                plain
                                v-model="formData.upazilla_id"
                                :options="upazilaList"
                                id="upazilla_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Crop" vid="crop_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="crop_id"
                                >
                              <template v-slot:label>
                                {{ $t('dae_config.crop_name') }} <span class="text-danger">*</span>
                              </template>
                                <b-form-select
                                    plain
                                    v-model="formData.crop_id"
                                    :options="cropList"
                                    id="crop_id"
                                    >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <ValidationProvider name="Purchase Order Date" vid="purchase_order_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="purchase_order_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('dae_grant_allocation_distribution.purchase_order_date') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  class="fromDate"
                                  id="purchase_order_date"
                                  v-model="formData.purchase_order_date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :placeholder="$t('dae_grant_allocation_distribution.purchase_order_date')"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Supplier Name" vid="supplier_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="supplier_id"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('dae_config.s_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              id="supplier_id"
                              :options="supplierList"
                              v-model="formData.supplier_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                              </template>
                            </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Supplier Mobile" vid="supplier_mobile">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="supplier_mobile"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('dae_grant_allocation_distribution.supplier_mobile') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="supplier_mobile"
                                  v-model="formData.supplier_mobile"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Supplier Address" vid="supplier_address" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="supplier_address"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('dae_grant_allocation_distribution.supplier_address') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="supplier_address"
                                  v-model="formData.supplier_address"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Supplier Address (Bn)" vid="supplier_address_bn" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="supplier_address_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('dae_grant_allocation_distribution.supplier_address_bn') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="supplier_address_bn"
                                  v-model="formData.supplier_address_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Payment Mode" vid="payment_mode" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="payment_mode"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('dae_grant_allocation_distribution.payment_mode') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  id="payment_mode"
                                  :options="paymentModeList"
                                  v-model="formData.payment_mode"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Payment Method" vid="payment_status" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="payment_status"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('dae_grant_allocation_distribution.payment_status') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  id="payment_status"
                                  :options="paymentStatusList"
                                  v-model="formData.payment_status"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Supplier Invoice Date" vid="supplier_invoice_date" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="supplier_invoice_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('dae_grant_allocation_distribution.supplier_invoice_date') }}
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  id="supplier_invoice_date"
                                  v-model="formData.supplier_invoice_date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :placeholder="$t('dae_grant_allocation_distribution.supplier_invoice_date')"
                              >
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Attachment" vid="supplier_invoice_attachment">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="supplier_invoice_attachment"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('dae_grant_allocation_distribution.supplier_invoice_attachment') }}
                              </template>
                              <b-form-file
                                  id="supplier_invoice_attachment"
                                  ref="supplier_invoice_attachment"
                                  v-model="formData.supplier_invoice_attachment"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-file>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              <a v-if="id && formData.supplier_invoice_attachment" class="btn btn-sm btn-success mt-2" target='_blank' :href="incentiveGrantServiceBaseUrl + 'uploads/purchase-register/original/' + formData.supplier_invoice_attachment" download><i class="fas fa-download"></i> {{ $t('globalTrans.view_download') }}</a>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </div>
                      <div class="invalid-feedback d-block" v-if="purchaseRegisterDetailsError">
                        Select at least one purchase register details info
                      </div>
                      <template>
                        <div style="font-size:15px; background-color: #005B5B;">
                          <h5 class="text-white text-center mb-3">
                              {{ $t('dae_grant_allocation_distribution.purchase_register') }} {{ $t('globalTrans.details') }} {{ $t('globalTrans.list') }}
                          </h5>
                        </div>
                      </template>
                      <div class="row">
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Material Type" vid="material_type_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="material_type_id"
                                >
                                <template v-slot:label>
                                  {{ $t('dae_config.agriculture_material_type') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formDataDetails.material_type_id"
                                    :options="agMaterialTypeList"
                                    id="material_type_id"
                                    >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <ValidationProvider name="Material Name" vid="material_id" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="material_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('dae_config.agriculture_material_name') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    id="material_id"
                                    plain
                                    v-model="formDataDetails.material_id"
                                    :options="materialList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <ValidationProvider name="Approve quantity" vid="approved_quantity" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="approved_quantity"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('purchInvent.approve_quantity') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      type="number"
                                      id="quantity"
                                      v-model="formDataDetails.approved_quantity"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      @change="getTotalPrice"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <ValidationProvider name="Quantity" vid="quantity" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="quantity"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.quantity') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      type="number"
                                      id="quantity"
                                      v-model="formDataDetails.quantity"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      @change="getTotalPrice"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Price Per Unit" vid="price_per_unit" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="price_per_unit"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('dae_grant_allocation_distribution.price_per_unit') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  type="number"
                                  id="price_per_unit"
                                  v-model="formDataDetails.price_per_unit"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @change="getTotalPrice"
                              >
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Total Price" vid="total_price" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="total_price"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('dae_grant_allocation_distribution.total_price') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  type="number"
                                  id="total_price"
                                  v-model="formDataDetails.total_price"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </div>
                      <div class="my-3 text-right">
                        <button @click="addItem" class="btn btn-sm btn-primary mr-2" type="button"
                        ><i class="fas fa-plus-circle m-0"></i> {{$t('globalTrans.add_more')}}</button>
                      </div>
                      <b-table-simple striped bordered small class="mt-2">
                        <b-thead>
                          <tr>
                              <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th>{{ $t('dae_config.agriculture_material_type') }}</b-th>
                              <b-th>{{ $t('dae_config.agriculture_material_name') }}</b-th>
                              <b-th class="text-center">{{ $t('globalTrans.quantity') }}</b-th>
                              <b-th class="text-right">{{ $t('dae_grant_allocation_distribution.price_per_unit') }}</b-th>
                              <b-th class="text-right">{{ $t('dae_grant_allocation_distribution.total_price') }}</b-th>
                              <b-th>{{ $t('globalTrans.action') }}</b-th>
                          </tr>
                        </b-thead>
                        <b-tbody>
                          <template v-if="formData.purchase_register_details.length">
                            <b-tr v-for="(item, index) in formData.purchase_register_details" :key="index">
                            <b-td  class="text-center">{{ index+1 }}</b-td>
                            <b-td class="text-center">{{ currentLocale === 'en' ? item.material_type_name : item.material_type_name_bn }}</b-td>
                            <b-td class="text-center">{{ currentLocale === 'en' ? item.material_name : item.material_name_bn }}</b-td>
                            <b-td class="text-center">{{ item.quantity }}</b-td>
                            <b-td class="text-right">{{ item.price_per_unit }}</b-td>
                            <b-td class="text-right">{{ item.total_price }}</b-td>
                            <b-td class="text-center">
                              <button @click="remove(index)" class="btn btn-sm btn-danger" type="button"><i class="fas fa-window-close m-0"></i></button>
                            </b-td>
                            </b-tr>
                          </template>
                          <template v-else>
                            <b-tr>
                              <b-td colspan="8" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                            </b-tr>
                          </template>
                        </b-tbody>
                        <b-tfoot>
                          <b-tr>
                            <b-td colspan="5" align="right">{{ $t('dae_grant_allocation_distribution.total_price') }}</b-td>
                            <b-td align="right">{{ grandTotal }}</b-td>
                            <b-td align="right"></b-td>
                          </b-tr>
                        </b-tfoot>
                      </b-table-simple>
                      <div class="row">
                        <div class="col-sm-3"></div>
                          <div class="col text-right">
                              <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { purchaseRegisterStore, purchaseRegisterUpdate, purchaseRegisterShow } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      this.getformDataData()
    }
  },
  data () {
    return {
      id: this.$route.query.id,
      formData: {
        fiscal_year_id: 0,
        season_id: 0,
        subsidy_type_id: 0,
        subsidy_id: 0,
        project_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        crop_id: 0,
        purchase_order_date: '',
        supplier_id: 0,
        supplier_mobile: '',
        supplier_address: '',
        supplier_address_bn: '',
        payment_mode: 0,
        payment_status: 0,
        supplier_invoice_date: '',
        supplier_invoice_attachment: null,
        purchase_register_details: []
      },
      formDataDetails: {
        material_type_id: 0,
        material_id: 0,
        approved_quantity: '',
        quantity: '',
        price_per_unit: '',
        total_price: ''
      },
      subsidyList: [],
      districtList: [],
      upazilaList: [],
      materialList: [],
      grandTotal: 0,
      isLoading: false,
      purchaseRegisterDetailsError: '',
      incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl
    }
  },
  watch: {
    'formData.supplier_id': function (newValue, OldValue) {
      const supplier = this.supplierList.find(item => item.value === newValue)
      this.formData.supplier_mobile = supplier?.mobile
      this.formData.supplier_address = supplier?.address
      this.formData.supplier_address_bn = supplier?.address_bn
    },
    'formData.subsidy_type_id': function (newValue) {
      this.subsidyList = this.getSubsidyList(newValue)
    },
    'formData.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
        this.upazilaList = []
    },
    'formData.district_id': function (newVal, oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
    },
    'formDataDetails.material_type_id': function (newVal, oldVal) {
        this.materialList = this.agMaterialList(newVal)
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  computed: {
    saveBtnName: function () {
      return this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
    },
    allocationList: function () {
      const allocationList = this.$store.state.incentiveGrant.commonObj.allocationList.filter(item => item.status === 1)
      return allocationList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: this.$n(parseInt(item.text_bn), { useGrouping: false }) }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    fiscalList: function () {
    const fiscalyearData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalyearData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    seasonList: function () {
      return this.$store.state.incentiveGrant.commonObj.seasonList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    subsidyTypeList: function () {
      const subsidyTypeList = this.$store.state.incentiveGrant.commonObj.subsidyTypeList.filter(item => item.status === 1)
      return subsidyTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    projectList: function () {
      const projectList = this.$store.state.incentiveGrant.commonObj.projectList.filter(item => item.status === 1)
      return projectList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    divisionList: function () {
      const divisionList = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      return divisionList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    cropList: function () {
      const cropData = this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
      return cropData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    agMaterialTypeList: function () {
      return this.$store.state.incentiveGrant.commonObj.agMaterialTypeList.filter(item => item.status === 1)
    },
    supplierList: function () {
      return this.$store.state.incentiveGrant.commonObj.supplierList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    paymentModeList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'Cash' : 'নগদ', value: 1 },
        { text: this.currentLocale === 'en' ? 'Credit' : 'ক্রেডিট', value: 2 }
      ]
    },
    paymentStatusList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'Paid' : 'পরিশোধ', value: 1 },
        { text: this.currentLocale === 'en' ? 'Unpaid' : 'অবৈতনিক', value: 2 }
      ]
    }
  },
  methods: {
    async getformDataData () {
      this.isLoading = true
      const result = await RestApi.getData(incentiveGrantServiceBaseUrl, purchaseRegisterShow + '/' + this.id)
      if (!result.success) {
        this.formData = []
      } else {
        this.formData = result.data
        this.formData.crop_id = result.data.purchase_register_details[0].crop_id
        this.formData.purchase_register_details = this.formData.purchase_register_details.map(item => {
          const agMaterialType = this.agMaterialTypeList.find(agMaterialTypeObj => agMaterialTypeObj.value === item.material_type_id)
          const agMaterial = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(agMaterialObj => agMaterialObj.value === item.material_id)
          const customItem = {}
          customItem.material_type_name = agMaterialType?.text_en
          customItem.material_type_name_bn = agMaterialType?.text_bn
          customItem.material_name = agMaterial?.text_en
          customItem.material_name_bn = agMaterial?.text_bn
          customItem.quantity = item.quantity
          customItem.approved_quantity = item.approved_quantity
          customItem.price_per_unit = item.price_per_unit
          customItem.total_price = item.total_price
          return Object.assign({}, item, customItem)
        })
        this.getGrandTotal()
      }
      this.isLoading = false
    },
    async saveData () {
      this.isLoading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        var formData = new FormData()
        Object.keys(this.formData).map(key => {
          if (key !== 'purchase_register_details') {
            formData.append(key, this.formData[key])
          } else {
            this.formData.purchase_register_details.filter((item, index) => {
              formData.append('purchase_register_details[' + index + '][material_type_id]', item.material_type_id)
              formData.append('purchase_register_details[' + index + '][material_id]', item.material_id)
              formData.append('purchase_register_details[' + index + '][approved_quantity]', item.approved_quantity)
              formData.append('purchase_register_details[' + index + '][quantity]', item.quantity)
              formData.append('purchase_register_details[' + index + '][price_per_unit]', item.price_per_unit)
              formData.append('purchase_register_details[' + index + '][total_price]', item.total_price)
            })
          }
        })
        if (this.id) {
        formData.append('_method', 'PUT')
          result = await RestApi.postData(incentiveGrantServiceBaseUrl, `${purchaseRegisterUpdate}/${this.id}`, formData)
        } else {
          result = await RestApi.postData(incentiveGrantServiceBaseUrl, purchaseRegisterStore, formData)
        }

        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
            this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
            this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
            })
            this.$router.push('purchase-register')
            this.purchaseRegisterDetailsError = ''
        } else {
            this.$refs.form.setErrors(result.errors)
          if (result.errors.purchase_register_details && result.errors.purchase_register_details.length) {
            this.purchaseRegisterDetailsError = result.errors.purchase_register_details[0]
          }
        }
      this.isLoading = false
    },
    getSubsidyList (subsidyTypeId) {
      const getSubsidyList = this.$store.state.incentiveGrant.commonObj.subsidyList.filter(item => item.status === 1 && item.subsidy_type_id === subsidyTypeId)
      return getSubsidyList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0 && item.division_id === divisionId)
      return districtList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0 && item.district_id === districtId)
      return upazilaList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    agMaterialList: function (materialTypeId = null) {
      const agMaterialList = this.$store.state.incentiveGrant.commonObj.agMaterialList.filter(item => item.status === 1 && item.material_type_id === materialTypeId)
      return agMaterialList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    getTotalPrice () {
      let totalPrice = 0
      if (this.formDataDetails.quantity && this.formDataDetails.price_per_unit) {
        totalPrice = parseFloat(this.formDataDetails.quantity) * parseFloat(this.formDataDetails.price_per_unit)
      }
      this.formDataDetails.total_price = totalPrice
    },
    getGrandTotal () {
      let grandTotal = 0
      this.formData.purchase_register_details.filter(item => {
        grandTotal += item.total_price
      })
      this.grandTotal = grandTotal
    },
    addItem () {
      if (this.formDataDetails.material_type_id && this.formDataDetails.material_id) {
          const agMaterialType = this.agMaterialTypeList.find(item => item.value === this.formDataDetails.material_type_id)
          const agMaterial = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(item => item.value === this.formDataDetails.material_id)
          const obj = {
            material_type_name: agMaterialType?.text_en,
            material_type_name_bn: agMaterialType?.text_bn,
            material_type_id: agMaterialType?.value,
            material_name: agMaterial?.text_en,
            material_name_bn: agMaterial?.text_bn,
            material_id: agMaterial?.value,
            quantity: this.formDataDetails.quantity,
            approved_quantity: this.formDataDetails.approved_quantity,
            price_per_unit: this.formDataDetails.price_per_unit,
            total_price: this.formDataDetails.total_price
          }
          this.formData.purchase_register_details.push(obj)
      }
      this.formDataDetails = {
        material_type_id: 0,
        material_id: 0,
        approved_quantity: '',
        quantity: '',
        price_per_unit: '',
        total_price: ''
      }
      this.$refs.form.reset()
      this.getGrandTotal()
    },
    remove (key) {
      this.formData.purchase_register_details.splice(key, 1)
      this.getGrandTotal()
    }
  }
}
</script>
